import moment from 'moment';

jQuery(document).ready(function ($) {
    var init = 0;
    $('body').on('click', function(e) {
        if (init == 0){
            $('#embed').attr('src','https://www.youtube.com/embed/SLqcPnKXolo?autoplay=1')
        }
        init=1;
    });

    // Form
    $("#lucky-daye-nl-code").on("submit", function (e) {
        // Prevent default browser actions on form submission.
        e.preventDefault();
        // Get all the form user submitted data and get it ready to be sent.
        var data = $(this).serialize();
        // POST all the data to the SMF submission endpoint URL.
        $.ajax({
            type: "POST",
            url: "https://subs.sonymusicfans.com/submit",
            dataType: "json",
            data: data,
            xhrFields: {
                withCredentials: false
            },
            success: function (data) {
                // Do things on successful submission.
                $('#lucky-daye-nl-code').hide();
                $('.form-success').show();
            },
            error: function (err) {
                // Do things when submission has an error.
                $('.form-error').show();
            }
        });
    });
    // Tour
    $.ajax( {
        url: 'https://rest.bandsintown.com/artists/Lucky%20Daye/events?app_id=45PRESS_lucky-daye',
        method: 'GET',
        dataType: 'json',
        xhrFields: {
            withCredentials: false
        },
        error: () => {
        },
        success: data => {
            const events = $( '#events' );
            let html = '';
            if ( data.length ) {
                for ( let event of data ) {
                    const event_location = typeof event.region !== 'undefined' ? event.venue.city + ', ' + event.venue.region : event.venue.city + ', ' + event.venue.country;
                    html += '<div class="event" data-bit-id="' + event.id + '">';
                    html += '<div class="event-date">' + moment( event.datetime ).format( 'MMM DD' ) + '</div>';
                    html += '<div class="event-venue-location">';
                    html += '<div class="event-venue">' + event.venue.name + '</div>';
                    html += '<div class="event-location">' + event_location + '</div>';
                    html += '</div>';
                    html += '<div class="event-links">';
                    html += '<a href="' + event.url + '" target="_blank" class="btn">Tickets</a>';
                    html += '</div>';
                    html += '</div>';
                }
                events.html( html );
            } else {
                events.html( 'No upcoming events.' );
            }
        }
    } );
    $('.tour-trigger').click(function (e) { 
        e.preventDefault();
        $('.shows').fadeToggle();
    });
    $('.close').click(function (e) { 
        e.preventDefault();
        $('.shows').fadeOut();
    });

    $('.music-trigger').click(function (e) { 
        e.preventDefault();
        $('.music').fadeToggle();
    });
    $('.music-close').click(function (e) { 
        e.preventDefault();
        $('.music').fadeOut();
    });
});